#sidenav-main .navbar-brand {
  color: #56abef !important;
  font-weight: 700 !important;
  font-size: 24px !important;
}

.mt--7 {
  margin-top: -10rem !important;
}
.react-bootstrap-table th .order-4:before,
.react-bootstrap-table th .caret-4-desc:before,
.react-bootstrap-table th .caret-4-asc:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 1;
  color: #037e08 !important;
  cursor: pointer;
}
.react-bootstrap-table th .order-4:after,
.react-bootstrap-table th .caret-4-desc:after,
.react-bootstrap-table th .caret-4-asc:after {
  content: "\2193";
  opacity: 1;
  color: red !important;
  cursor: pointer;
}
#review .card .table {
  margin-bottom: 0;
}
/* button#pageDropDown {
  display: none !important;
} */
#review .card .table td:nth-child(4) {
  max-width: 400px !important;
}
#review .card .table td:nth-child(4) button {
  background-color: transparent;
  border: 0;
  color: blue;
}
.react-bootstrap-table th {
  position: relative;
}
#review input#text-filter-column-reviewerUsername {
  position: absolute;
  width: 100%;
  top: 5px;
  left: 9px;
  right: 30px;
  height: 35px;
}
#review input#text-filter-column-rating {
  position: absolute;
  width: 100%;
  top: 5px;
  left: 15px;
  right: 30px;
  height: 35px;
}
#review input#text-filter-column-comment {
  position: absolute;
  width: 90%;
  top: 5px;
  left: 25px;
  right: 30px;
  height: 35px;
}
#review input#text-filter-column-email {
  position: absolute;
  width: 100%;
  top: 5px;
  left: 2px;
  height: 35px;
}
/* user */

.table td,
.table th {
  vertical-align: middle !important;
}

#provider .card .table td:nth-child(5),
#provider .card .table td:nth-child(6) {
  max-width: 400px !important;
}
#provider .card .table td:nth-child(5) {
  overflow: hidden;
  text-overflow: ellipsis;
}
#provider .card .table td:nth-child(6) button {
  background: transparent;
  border: 0;
  color: blue;
}
#provider input#text-filter-column-email {
  position: absolute;
  width: 100%;
  top: 5px;
  left: 12px;
  height: 35px;
  font-size: 13px;
}
#provider input#text-filter-column-subCategory {
  position: absolute;
  width: 100%;
  top: 5px;
  left: 12px;
  height: 35px;
  font-size: 13px;
}
#provider input#text-filter-column-name {
  position: absolute;
  width: 90%;
  top: 5px;
  left: 18px;
  height: 35px;
  font-size: 13px;
}
#provider input#text-filter-column-tagline {
  position: absolute;
  width: 90%;
  top: 5px;
  left: 18px;
  height: 35px;
  font-size: 13px;
}

#provider input#text-filter-column-description {
  position: absolute;
  width: 90%;
  top: 5px;
  left: 12px;
  height: 35px;
  font-size: 13px;
}
#provider .card .table,
#user .card .table {
  margin-top: 50px;
}

#user input#text-filter-column-displayName {
  position: absolute;
  top: 5px;
  width: 100%;
  left: 12px;
  height: 37px;
  font-size: 13px;
}

#user input#text-filter-column-email {
  position: absolute;
  top: 5px;
  width: 100%;
  left: 18px;
  height: 37px;
  font-size: 13px;
}

#user input#text-filter-column-phone {
  position: absolute;
  top: 5px;
  width: 100%;
  left: 22px;
  height: 37px;
  font-size: 13px;
}

#user input#text-filter-column-linked_accounts {
  position: absolute;
  top: 5px;
  width: 100%;
  left: 30px;
  height: 37px;
  font-size: 13px;
}

#user input#text-filter-column-date_time {
  position: absolute;
  top: 5px;
  width: 95%;
  left: 35px;
  height: 37px;
  font-size: 13px;
}

#user input#text-filter-column-lastLogin {
  position: absolute;
  top: 5px;
  width: 90%;
  left: 30px;
  height: 37px;
  font-size: 13px;
}

input#text-filter-column-operation {
  position: absolute;
  width: 85%;
  top: -44px;
  left: 17px;
  height: 35px;
  font-size: 13px;
}

#dropdownMenuButton {
  padding: 4px 8px !important;
  text-transform: capitalize !important;
}

@media (max-width: 551px) {
  td {
    font-size: 12px !important;
  }
  input[type="text"] {
    font-size: 12px !important;
    height: 30px !important;
  }
  .pagination {
    justify-content: center !important;
  }
  .pagination li {
    margin-right: 8px !important;
  }
  .pagination li:last-child {
    margin-right: 0 !important;
  }
  .page-item .page-link {
    width: 28px !important;
    height: 28px !important;
    font-size: 12px !important;
    margin: 0 !important;
  }
  .card h3 {
    font-size: 15px !important;
  }
}

@media (max-width: 1220px) {
  #review .table {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch !important;
  }
}

.show-less {
  white-space: pre-line;
  overflow: none;
}

#user .card .table td:nth-child(4) {
  text-align: center;
}

.spinner {
  text-align: center;
}

.spinner > div {
  width: 8px;
  height: 8px;
  margin-right: 4px;
  background-color: #119def;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  background-color: #119def;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  background-color: #119def;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

#user .table,
#provider .table {
  display: block !important;
  width: 100% !important;
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch !important;
}

.react-bootstrap-table-pagination-list {
  width: 100%;
  margin-left: auto;
  padding-right: 30px;
}
.table thead th {
  padding: 60px 0 0 0;
}
#provider .card .table,
#user .card .table {
  margin-top: 0 !important;
}

.react-bs-table-sizePerPage-dropdown.dropdown .btn-default {
  margin-top: 7px !important;
  color: #fff;
  background-color: #65a8ee !important;
  border-color: #65a8ee;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  padding: 4px 4.5px !important;
}
#review .table {
  width: 100% !important;
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch !important;
}

/* desktop */
.row.react-bootstrap-table-pagination .col-xs-6:nth-child(1) {
  position: absolute;
  top: 11px;
  width: 0;
  right: 19.3rem;
}

div#page-dropdown {
  position: absolute;
  top: 17px;
  right: 155px;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.records-title {
  margin-right: 0px;
}

.page-title {
  margin-right: 10px;
}

.dyn_drop {
  padding: 0 37px;
}

.dyn_drop_ {
  padding: 0 40px;
}

.ml_heading {
  margin-left: 25px;
}

@media (max-width: 768px) {
  .table-header {
    flex-direction: column;
  }
  .row.react-bootstrap-table-pagination .col-xs-6:nth-child(1) {
    top: 51px;
    right: unset;
    left: 22%;
  }
  div#page-dropdown {
    top: 99px;
    right: unset;
    left: 15%;
  }
  .table-wrapper {
    flex-wrap: wrap;
  }
  .records-title {
    width: 50%;
  }
  .page-title {
    width: 50%;
    margin-right: auto;
  }
  .total-title {
    width: 100%;
  }
}

@media (max-width: 551px) {
  .row.react-bootstrap-table-pagination .col-xs-6:nth-child(1) {
    top: 43px;
    left: 27%;
  }
  .records-title {
    width: 100%;
    margin-top: 10px;
  }
  .page-title,
  .total-title {
    width: 100%;
  }
  div#page-dropdown {
    top: 90px;
    left: 20%;
  }
}

#mob_view {
  display: none;
}

@media (max-width: 768px) {
  #mob_view {
    display: block !important;
    position: relative;
  }
  #mob_view .btn-primary {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 7px 10px !important;
  }

  #dektp_view {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .row.react-bootstrap-table-pagination .col-xs-6:nth-child(1) {
    top: 49.5px;
    left: 29.5%;
  }
  div#page-dropdown {
    left: 22%;
    top: 92px;
  }
  #dropdownMenuButton {
    font-size: 11.5px;
  }
  .react-bs-table-sizePerPage-dropdown.dropdown .btn-default {
    font-size: 12px;
  }
}
@media (max-width: 411px) {
  .row.react-bootstrap-table-pagination .col-xs-6:nth-child(1) {
    top: 49.5px;
    left: 34.5%;
  }
  .dyn_drop {
    padding: 0 30px;
  }
  #mob_view .btn-primary{
    padding: 4px 7px !important;

  }
}
@media (max-width: 390px) {
  .row.react-bootstrap-table-pagination .col-xs-6:nth-child(1) {
    top: 49.5px;
    left: 39%;
  }
  div#page-dropdown {
    left: 25%;
    top: 92px;
  }
  .dyn_drop {
    padding: 0 32px;
  }
}
@media (max-width: 340px) {
  .row.react-bootstrap-table-pagination .col-xs-6:nth-child(1) {
    left: 43%;
  }
  div#page-dropdown{
    left: 30%;
  }
}
